import React, { useContext, CSSProperties } from 'react';
import { MdAttachMoney } from 'react-icons/md';
import { BsTruckFlatbed } from 'react-icons/bs';
import { Button } from 'react-bootstrap';
import { AppContext } from '../../AppContext';

interface Props {
    id?: string,
    className?: string,
    style?: CSSProperties,
    errors: {[field: string]: boolean},
    onComplete: () => void,
}

const FinancingFreight = (props: Props) => {

    const context = useContext(AppContext);

    return (
        <div id={props.id} className={props.className} style={props.style}>
            <p className='fs-5'>{context.translations.get('sandhills.financingorfreight')}</p>
            <div className='d-grid gap-3 m-3'>
                <a href='https://app.gocurrency.com/?vendorId=100005' target='_blank'><Button size='lg' variant='gray' className='w-100'><MdAttachMoney size={30} className='text-light-gray'/>{context.translations.get('sandhills.financing')}</Button></a>
                <a href='https://app.fr8star.com/' target='_blank'><Button size='lg' variant='gray' className='w-100'><BsTruckFlatbed size={30} className='me-3 text-light-gray'/>{context.translations.get('sandhills.freight')}</Button></a>
                <span role='button' className='text-decoration-underline mx-auto' onClick={() => props.onComplete()}>{context.translations.get('sandhills.neithergetvaluesnow')}</span>
            </div>
        </div>
    );
}

export default FinancingFreight;