// THIS FILE WAS GENERATED AUTOMATICALLY
//
// SEE README IN Sandhills.ValueInsightPortal/Models

import { AppInitialization } from '../models/AppInitialization';
import { SelectOptionList } from '../models/SelectOptionList';
import { LocaleSelectOption } from '../models/LocaleSelectOption';
import { LocationLocale } from '../models/LocationLocale';
import { LeadFormSubmission } from '../models/LeadFormSubmission';
import { Base } from '../models/Base';
import { VIPOptionList } from '../models/VIPOptionList';


import FetchProvider from './client/FetchProvider';

export default class AppProvider {
    // -------------------------------------------------------------------------
    // ------------------------------ GET METHODS ------------------------------
    // -------------------------------------------------------------------------

    static InitializeApp(): Promise<AppInitialization | null | undefined> {
        return FetchProvider.fetch<AppInitialization>(`/api/app/initializeapp`);
    }

    static GetSupportedLocales(): Promise<SelectOptionList<LocaleSelectOption> | null | undefined> {
        return FetchProvider.fetch<SelectOptionList<LocaleSelectOption>>(`/api/app/getsupportedlocales`);
    }

    static GetCultureFromLocation(latitude?: number | null | undefined, longitude?: number | null | undefined): Promise<LocationLocale | null | undefined> {
        return FetchProvider.fetch<LocationLocale>(`/api/app/getculturefromlocation?latitude=${encodeURIComponent(latitude || '')}&longitude=${encodeURIComponent(longitude || '')}`);
    }

    static GetVIPApplicationURLs(TLD: string | null | undefined, market: string | null | undefined): Promise<VIPOptionList | null | undefined> {
        return FetchProvider.fetch<VIPOptionList>(`/api/app/getvipapplicationurls?TLD=${encodeURIComponent(TLD || '')}&market=${encodeURIComponent(market || '')}`);
    }

    // --------------------------------------------------------------------------
    // ------------------------------ POST METHODS ------------------------------
    // --------------------------------------------------------------------------

    static SubmitLeadForm(input: LeadFormSubmission): Promise<Base | null | undefined> {
        return FetchProvider.fetch<Base>(`/api/app/submitleadform`, input);
    }
}