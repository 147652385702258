import React from "react";


// This class handles translations
export default class TranslationProvider {
    private translations: { [key: string]: string };
    private debugMode: boolean = false;

    constructor(translations: { [key: string]: string }, debugMode: boolean = false) {
        this.translations = translations;
        this.debugMode = debugMode;

        if (this.debugMode)
            window.alert('Translations are in debug mode. "########" means there is a missing key, or the key hasn\'t been linked to the namespace. "*********" means a valid translation would be shown and no changes are necessary. Anything showing up in english was missed and still needs to be replaced with a translation.');
    }

    get(key: string): string {
        if (!key) {
            console.error('A key is required to use translations.');
            return '#########';
        }

        let lowerKey = key.toLowerCase();

        if (this.translations) {
            if (this.translations[lowerKey]) {
                if (this.debugMode)
                    return '***********';

                return this.translations[lowerKey];
            } else {
                console.info(`%c
Translation key '${lowerKey}' is missing, or hasn't been linked to this namespace. Follow the steps below to fix the issue: \n\n
• Search for matching or similar english phrases here: http://intranet/TranslationsMVC/  \n
• If needed, add a new key / english phrase here: http://intranet/TranslationsMVC/Key/Add?keyName=${encodeURIComponent(lowerKey)}  \n
• Link the key to our namespace here: http://intranet/TranslationsMVC/Namespace/AddNamespaceLink?keyName=${encodeURIComponent(lowerKey)}&namespace=${encodeURIComponent('Sandhills.ValueInsightPortal')}  \n\n
• Clear the cache after adding all missing keys: https://${window.location.host}/cache-handler/search?searchTerm=Translations_Get  \n\n

Note - Only add a new key if an existing one can't be used. Make sure to use the correct key in the third step if one already exists.
                `, 'color: #4880DB');
            }
        } else {
            console.error('TranslationProvider was not initialized with translations.');
        }

        return '#########';
    }

    exists(key: string): boolean {
        if (!key) {
            console.error('No key provided.');
            return false;
        }

        let lowerKey = key.toLowerCase();

        return this.translations && this.translations[lowerKey] !== null && this.translations[lowerKey] !== undefined && this.translations[lowerKey] !== '';
    }
}