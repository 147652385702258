import React, { CSSProperties, forwardRef, LegacyRef, useContext, useEffect, useImperativeHandle, useRef } from 'react';
import { Spinner } from 'react-bootstrap';
import ReCaptcha from 'react-google-recaptcha';
import { AppContext } from '../../AppContext';

interface Props {
    id?: string,
    style?: CSSProperties,
    className?: string,
    loading?: boolean,
    onComplete: (success: boolean) => void
}

export type CaptchaHandle = {
    reset: () => void,
    execute: () => void
}

const Captcha = forwardRef<CaptchaHandle, Props>((props, ref) => {
    const context = useContext(AppContext);
    const captchaRef = useRef<ReCaptcha>(null);

    useImperativeHandle(ref, () => ({
        reset: () => {
            captchaRef.current?.reset();
        },
        execute: () => {
            captchaRef.current?.execute();
        }
    }));

    const handleChange = (token: string | null) => {
        if (token) {
            props.onComplete(true);
        } else {
            props.onComplete(false);
        }
    }

    return (
        <div id={props.id} style={props.style} className={props.className}>
            <p className='fs-5 mb-5'>{context.translations.get('sandhills.checkboxtosubmit')}</p>
            <label className='d-none' htmlFor="g-recaptcha-response">Captcha</label>
            <ReCaptcha ref={captchaRef} sitekey={context.googleReCaptchaSiteKey} hl={context.googleReCaptchaLanguageCode} size={'normal'} theme='dark' badge='inline' className={props.loading ? 'd-none' : 'd-inline-block'} onChange={handleChange} />
            {props.loading && <Spinner variant='primary' as='span' animation='border' />}
        </div>
    );
});

export default Captcha;