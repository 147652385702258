import React, { CSSProperties, useState } from 'react';
import { useContext } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { FaUnlock } from 'react-icons/fa';
import { TextInput, DropdownInput } from 'sandhills-react-bootstrap-ui/dist/esm';
import { AppContext } from '../../AppContext';

interface Props {
    id?: string,
    className?: string,
    style?: CSSProperties,
    errors: {[field: string]: boolean},
    onComplete: (name: string, email: string, phoneNumber: string, market: string) => void
}

const ContactInformation = (props: Props) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [market, setMarket] = useState<{ value: any, label: string } | null>(null);

    const context = useContext(AppContext);

    const handleUnlockClick = () => {
        props.onComplete(name, email, phoneNumber, market?.value);
    }

    const MARKET_OPTIONS = [
        { label: context.translations.get('Sandhills.Trucks'), value: 'Trucks' },
        { label: context.translations.get('Sandhills.AgriculturalEquipment'), value: 'Agricultural Equipment' },
        { label: context.translations.get('Sandhills.ConstructionEquipment'), value: 'Construction Equipment' },
        { label: context.translations.get('Sandhills.CRM.DSCRM.Other'), value: 'Other' }
    ];

    return (
        <div id={props.id} style={props.style} className={props.className}>
            <p className='fs-5'>{context.translations.get('sandhills.pleasefilloutcontactinfo')}</p>
            <Row className='text-start'>
                <Col xs='12' xl className='mb-3 mb-xl-0'>
                    <TextInput className={props.errors['name'] ? 'error' : ''} placeholder={context.translations.get('sandhills.yourname')} onChange={setName} ariaProps={{"aria-label": context.translations.get('sandhills.yourname')}} />
                </Col>
                <Col xs='12' xl className='mb-3 mb-xl-0'>
                    <TextInput className={props.errors['email'] ? 'error' : ''} placeholder={context.translations.get('sandhills.youremail')} onChange={setEmail} ariaProps={{"aria-label": context.translations.get('sandhills.youremail')}} />
                </Col>
                <Col xs='12' xl className='mb-3 mb-xl-0'>
                    <TextInput className={props.errors['phoneNumber'] ? 'error' : ''} placeholder={context.translations.get('sandhills.yourphonenumber')} onChange={setPhoneNumber} ariaProps={{"aria-label": context.translations.get('sandhills.yourphonenumber')}} />
                </Col>
                <Col xs='12' xl className='mb-3 mb-xl-0'>
                    <DropdownInput className='dropdown' placeholder={context.translations.get('sandhills.marketinterest')} options={MARKET_OPTIONS} onChange={setMarket} ariaProps={{"aria-label": context.translations.get('sandhills.marketinterest')}} />
                </Col>
                <Col xs='12' className='d-grid flex-grow-1 flex-xl-grow-0' style={{ flexBasis: 270 }}>
                    <Button onClick={handleUnlockClick}><FaUnlock size={22} /> {context.translations.get('sandhills.unlockfreeaccess')}</Button>
                </Col>
            </Row>
        </div>
    );
}

export default ContactInformation;