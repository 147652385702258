import React from 'react';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AppContext } from '../../AppContext';

interface Props {

}

const NotFound = (props: Props) => {

    const context = useContext(AppContext);

    return (
        <div className='w-100 text-center'>
            <div style={{ maxWidth: '100%', width: 500 }} className='bg-dark d-inline-block p-3 mx-3 border border-primary rounded mt-5'>
                <p className='fs-5'>404, {context.translations.get('sandhills.pagenotfound')}.</p>
                <Link to='/'>
                    <Button>{context.translations.get('Sandhills.MobileApps.iOS.Home')}</Button>
                </Link>
            </div>
        </div>
    );
}

export default NotFound;