import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AppContext } from '../../AppContext';

interface Props {

}

const ServerInfo = (props: Props) => {
    const context = useContext(AppContext);

    return (
        <div className='w-100'>
            <div className='bg-dark p-3 mx-3 border border-primary rounded mt-5'>
                <Link to='/'>Return To Home</Link>
                <br/>
                <br/>
                <code>
                    <pre>
                        {JSON.stringify({...context, translations: '...'}, null, 2)}
                    </pre>
                </code>
            </div>
        </div>
    );
}

export default ServerInfo;