
import React from 'react';
import Footer from './Footer';
import Header from './Header';

interface Props {

}

const Layout = (props: React.PropsWithChildren<Props>) => {

    return (
        <div id='layout-root' style={{ overflowY: 'auto', overflowX: 'hidden' }} className='d-flex flex-column h-100'>
            <Header />
            <div className='d-flex flex-grow-1'>
                {props.children}
            </div>
            <Footer />
        </div>
    );
}

export default Layout;
