import React, { useContext } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FaPhoneAlt, FaBars } from 'react-icons/fa';
import { AppContext } from '../AppContext';
import LocaleDropdown from '../shared/LocaleDropdown';

interface Props {

}

const Header = (props: Props) => {
    const context = useContext(AppContext);

    const handleLocaleChange = (cultureCode: string) => {
        context.initializeApp(cultureCode);
    }

    return (
        <>
            <Row className='my-3 px-lg-5 px-3 align-items-center'>
                <Col className='order-first d-flex align-items-center'>
                    <a style={{ textDecoration: 'none' }} className='fw-bold text-lg text-white-hover' href={`tel:${context.contactPhoneNumber}`}><FaPhoneAlt className='text-primary' /> {context.contactPhoneNumber}</a>
                    <LocaleDropdown onChange={handleLocaleChange} className='ms-3' />
                </Col>
                <Col sm='12' lg className='text-center order-last order-lg-2 mt-2 mt-lg-0'>
                    <img style={{ maxWidth: '100%' }} src='https://media.sandhills.com/cdn/images/vip/logo-light.png' alt={context.translations.get('sandhills.viplogo')} />
                </Col>
                <Col className='order-2 order-lg-last justify-content-end align-items-center d-none d-lg-flex'>
                    <a target='_blank' href={context.vipSiteURL}>
                        <Button variant='clear-primary' className='responsive'>{context.translations.get('sandhills.getvaluesnow')}</Button>
                    </a>
                </Col>
            </Row>
        </>
    );
}

export default Header;