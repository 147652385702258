import React, { CSSProperties, useEffect } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { VIPOption } from '../../models/VIPOption';
import AnimatedCheckmark from '../../shared/AnimatedCheckmark';
import $ from 'jquery';
import { useContext } from 'react';
import { AppContext } from '../../AppContext';

interface Props {
    id?: string,
    loading: boolean,
    vipLinks: VIPOption[],
    style?: CSSProperties,
    className?: string
}

const Confirmation = (props: Props) => {

    const context = useContext(AppContext);

    useEffect(() => {
        if (props.vipLinks && props.vipLinks.length) {
            $('#link-div').slideDown();
        }
    }, [props.vipLinks]);

    return (
        <div id={props.id} style={props.style} className={props.className}>
            {props.loading && <Spinner as='span' animation='border' />}
            {!props.loading && <AnimatedCheckmark />}
            <div style={{ display: 'none' }} id='link-div'>
                {props.vipLinks.length > 1 && <p className='mt-4 fs-5'>{context.translations.get('sandhills.clicklinktostartusingvip')}</p>}
                {props.vipLinks.length === 1 && <p className='mt-4 fs-5'>{context.translations.get('sandhills.clicklinktostartimmediately')}</p>}
                <Row className='justify-content-around'>
                    {props.vipLinks.map((link, idx) => (
                        <Col xs='12' lg='3' key={link.publicationName + idx} className='mt-3'>
                            <a href={link.url} target='_blank' className='vip-link h-100 p-2 text-cond fw-bold d-block rounded'>
                                <img width={100} src={link.iconURL} alt={link.publicationName}/>
                                <p className='mt-2 mb-0'>{link.publicationName}</p>
                            </a>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    );
}

export default Confirmation;