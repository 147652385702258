
import { Base } from '../../models/Base';
import { toast } from 'react-toastify';

export default class FetchProvider {
    static MAX_FILE_SIZE = 1048576;
    static MAX_UPLOAD_RETRIES = 5;

    static fetch = <T extends Base>(url: string, input?: any): Promise<T | null | undefined> => {
        if (url) {
            //Used for error reporting
            let sections = url.split('/');
            let endpoint = '';
            if (sections.length && sections[sections.length - 1])
                endpoint = sections[sections.length - 1].split('?')[0];

            let config: any = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            if (input) {
                config.method = 'POST';
                config.body = JSON.stringify(input);
            }

            return window.fetch(url, config).then((response) => {
                if (response.ok && !response.redirected) {
                    return response.json().then((data: T) => {
                        if (!data || (!data.success && data.displayError)) {
                            console.log(data.detailedErrorMessage);
                            toast.error(data.messageToDisplay);
                            return null;
                        } else if (!data.success && !data.displayError) {
                            console.log(data.detailedErrorMessage);
                            return null;
                        } else {
                            return data;
                        }
                    }).catch((err) => {
                        console.log(err);

                        // Try to output any data returned
                        // No need to wait for this (no return / await)
                        response.text().then((responseText) => {
                            console.log(responseText);
                        }).catch((e) => {
                            console.log('Failed getting response text.', e);
                        });

                        toast.error('There was an unhandled error.');
                        return null;
                    });
                } else if (response.redirected) {
                    // Redirect them to the account/login ?
                    // window.location.href = '/Account/Login';
                    // console.log(response.url);
                    console.log(`Request to ${endpoint} was redirected to ${response.url}.`);
                    toast.error('Authentication expired. Please login again.');

                    let url = new URL(response.url);

                    let params = new URLSearchParams(url.search);
                    params.set('redirected', 'true');

                    url.search = params.toString();

                    window.location.href = url.toString();

                    return null;
                } else {
                    //Try to get any info we can
                    response.text().then((responseText) => {
                        console.log(responseText);
                    }).catch((e) => {
                        console.log('Failed getting response text.', e);
                    });

                    console.log(response);

                    toast.error('Unhandled error during fetch.');
                    return null;
                }
            }).catch((err) => {
                console.log(err);
                toast.error('Unhandled error during fetch.');
                return null;
            });
        } else {
            toast.error('Attempted fetch without URL.');
            return Promise.resolve(null);
        }
    }

    // static fetchFile = (url: string, fileName: string, input?: any): Promise<void> => {
    //     if (url) {
    //         //Used for error reporting
    //         let sections = url.split('/');
    //         let endpoint = '';
    //         if (sections.length && sections[sections.length - 1])
    //             endpoint = sections[sections.length - 1].split('?')[0];

    //         let config: any = {
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             }
    //         };
    //         if (input) {
    //             config.method = 'POST';
    //             config.body = JSON.stringify(input);
    //         }

    //         return window.fetch(url, config).then((response) => {
    //             if (response.ok && !response.redirected) {
    //                 return response.blob().then((blob) => {
    //                     const fileURL = window.URL.createObjectURL(blob);
    //                     const fileDownloadElement: HTMLAnchorElement = document.createElement('a');
    //                     fileDownloadElement.hidden = true;
    //                     fileDownloadElement.href = fileURL;
    //                     fileDownloadElement.download = fileName;
    //                     const body = document.querySelector('body');
    //                     body?.appendChild(fileDownloadElement);

    //                     fileDownloadElement.click();

    //                     return;
    //                 }).catch((err) => {
    //                     console.log(err);
    //                     toast.error('There was an unhandled error.');
    //                     return;
    //                 });
    //             } else if (response.redirected) {
    //                 // Redirect them to the account/login ?
    //                 // window.location.href = '/Account/Login';
    //                 console.log(`Request to ${endpoint} was redirected.`);
    //                 toast.error(`There was an error submitting the request.`);
    //                 return;
    //             } else {
    //                 console.log(response);
    //                 toast.error('Unhandled error during fetch.');
    //                 return;
    //             }
    //         }).catch((err) => {
    //             console.log(err);
    //             toast.error('Unhandled error during fetch.');
    //             return;
    //         });
    //     } else {
    //         toast.error('Attempted fetch without URL.');
    //         return Promise.resolve();
    //     }
    // }
}