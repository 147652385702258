import React, { CSSProperties } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import AnimatedCheckmark from '../../shared/AnimatedCheckmark';
import { useContext } from 'react';
import { AppContext } from '../../AppContext';

interface Props {
    id?: string,
    style?: CSSProperties,
    className?: string
}

const FormError = (props: Props) => {

    const context = useContext(AppContext);

    return (
        <div id={props.id} style={props.style} className={props.className}>
            <p className='fs-5 mb-5 fw-bold text-danger'>{context.translations.get('sandhills.sorrysomethingwentwrong')}</p>
        </div>
    );
}

export default FormError;