import React from 'react';

interface Props {

}

const AnimatedCheckmark = (props: Props) => {
    return (
        <svg className="checkmark-anim" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle className="checkmark-anim-circle" cx="26" cy="26" r="25" fill="none" />
            <path className="checkmark-anim-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </svg>
    );
}

export default AnimatedCheckmark;