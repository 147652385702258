import React from 'react';
import { Link } from 'react-router-dom';
import '../privacypolicy/table.scss';

interface Props {

}

const CookiePolicy = (props: Props) => {
    return (
        <div className='m-3 sub-page'>
            <div>
                <h1>Sandhills Cookies Policy</h1>
                <Link to='/'>Return Home</Link>
                <p>
                    <strong>Who we are and how to contact us</strong><br />
                    This website is operated by Sandhills Global, Inc. ("we", "us" or "our"), a corporation registered in the State of Nebraska (USA) with registered address at 120 W. Harvest Drive, Lincoln, Nebraska, 68521, USA.<br /><br />

                    This Cookies Policy applies to the Brands and Services and their corresponding Websites (the Websites) which are owned and operated by Sandhills Group Companies.<br /><br />

                    Please also see our Privacy Notice for further details about the ways in which we collect and use your personal data. For more information, please Contact Us.<br /><br />

                    <strong>What are cookies?</strong><br />
                    Cookies are small data files that are stored in your web browser on your computer, tablet or smartphone (each a "Device") when you visit our website. They enable our website to remember your actions and preferences over a certain period of time.<br /><br />

                    Most web browsers automatically accept cookies, but if you do not want to allow cookies or only allow the use of certain types of cookies, please refer to your browser settings which should allow you to accept or deny cookies (please read section [6] below for more details about how to do this). You can also use the browser settings to withdraw your consent to the use of cookies at any time or delete cookies that have already been set on your Device. Keep in mind that by disabling certain categories of cookies, you may be prevented from accessing some features of our websites. You should be able to find more in depth information about opting out of cookies on your browser settings menu.<br /><br />

                    <strong>Do we use cookies?</strong><br />
                    Yes, we use cookies and similar technologies (hereinafter collectively referred to as "cookies") to enhance the customer experience of using our websites. The information is used to customise your visit by storing your search details to the browser, saving your shortlists and for interacting with social media platforms from within our site. By storing cookies it enables us to save your recent searches and the holidays you have viewed, in order to save you time when you revisit the site.<br /><br />

                    We use third party cookies and may place or identify a unique cookie, from the customer referred to in the advertisement, on your browser. By placement of the advertisement, information about your visit to the website is collected (e.g. how often you have seen an advertisement).<br /><br />

                    We also use cookies and software tools to collect and analyse site usage data, related to customer use and patterns. This data is used to improve our Websites and enhance user experience.<br /><br />

                    We may place cookies on partner websites to advertise our brands and/or products. These cookies are mainly used to display content tailored to your interests and to evaluate our content (including advertising). Our cookies are also used by third party sites in order to provide advertising that is relevant. This relevance is based on a profile built form your previous visits to our sites, including the products you have visited. Please see our section on third party cookies below. See "Do We Host Third Party Cookies?"<br /><br />

                    <strong>Why do we use cookies?</strong><br />
                    We use cookies for the following purposes:<br />
                    <ul>
                        <li>To customize your visit.</li>
                        <li>To improve our websites.</li>
                        <li>To provide customers with advertising that is relevant (third parties are involved in providing this).</li>
                        <li>To analyse and compile collected data to build a profile about customers.</li>
                        <li>For statistical purposes such as:</li>
                        <li>Pages viewed during a visit.</li>
                        <li>Type of device, OS, screen size.</li>
                        <li>Data is used to improve existing features and user experience on desktop, mobile, etc.</li>
                        <li>To collect the following information:</li>
                        <li>Information regarding what customers search for.</li>
                        <li>Information on what customers view.</li>
                        <li>Information on what customers click on.</li>
                        <li>Information on what customers access in and through the websites, our marketing emails, and text messages including pages visited within our websites.</li>
                        <li>Information regarding customer shortlists.</li>
                        <li>Date, geographic location, and time customer visits our websites.</li>
                        <li>Data related to customer use and patterns on websites.</li>
                        <li>Data regarding products and services customers have visited.</li>
                        <li>Data regarding type of browser being used (Mozilla Firefox, Google Chrome, Internet Explorer).</li>
                        <li>The website from which the user has accessed our websites.</li>
                        <li>Websites users went to when they left our sites.</li>
                        <li>Data regarding how often users visit and use the websites</li>
                    </ul><br /><br />

                    <strong>Which cookies do we use?</strong>
                </p>

                <table>
                    <tr>
                        <td>
                            <strong>Source (Where cookies come from)</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            First Party Cookies
                        </td>
                        <td>
                            These cookies are provided by us and used by various features on our site. Examples may include customizing a user’s visit, providing statistical information, and verifying a user’s identity for security purposes.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Third Party Cookies
                        </td>
                        <td>
                            These cookies are provided by "Third Party" vendors who provide products and features we have integrated into our site. Examples include social media sites, ad networks, and security systems. See "Do We Host Third Party Cookies?"
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Expiration (How long cookies last)</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Session Cookies
                        </td>
                        <td>
                            These cookies are added when a user starts to browse our site or interacts with a specific feature and are deleted when the browser is closed.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Persistent Cookies
                        </td>
                        <td>
                            These cookies are added when a user starts to browse our site or interacts with a specific feature, but may remain stored on your device until a certain termination date is reached (in terms of minutes, days or years from the creation/update of the cookie).<br />
                            Cookies used by us have a maximum period of 2 years from the date when they are initially installed or if they are subsequently updated, from the date of the update.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Type (What application uses the cookie)</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Email Cookies
                        </td>
                        <td>
                            These cookies are added by programs we use to send various mass marketing e-mail campaigns. They are used to track statistics about the mail campaign.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Flash Cookies
                        </td>
                        <td>
                            These cookies are added when a browser displays a Flash application. Flash is used to display some versions of our digital publications. The cookie may be used to track statistical information pertaining to the digital publication, such as pages were viewed.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Website Cookies
                        </td>
                        <td>
                            These cookies are added when browsing our website. Their uses may vary, however, to give an impression of which website cookies we use and how they work, please see the list below.
                        </td>
                    </tr>
                </table>

                <p>
                    <strong>Website Cookies</strong><br />
                    Further details on our specific use of cookies are contained in the following table:
                </p>

                <table>
                    <tr>
                        <td>
                            <strong>Categories</strong>
                        </td>
                        <td>
                            <strong>Examples</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Ad Network
                        </td>
                        <td>
                            AID<br />
                            ___gads
                        </td>
                        <td>
                            Third party cookies that are used to display Ads. Sources include Google and DoubleClick
                        </td>
                    </tr>
                    <tr>
                        <td>
                            AddThis
                        </td>
                        <td>
                            __atuvc<br />
                            __atuvs<br />
                            ATUVC<br />
                            ATUVS
                        </td>
                        <td>
                            Third party cookies provided by AddThis. Allows for users to easily share our content over various social media platforms.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Security
                        </td>
                        <td>
                            BIGipServer
                            __cf
                            D_
                            SANDHILLS
                            TS
                            __RequestVerificationToken<br />
                            AUTHENTICATIONREDIRECT<br />
                            SSOReturn<br />
                            SSOFB<br />
                            Fedauth1
                        </td>
                        <td>
                            First and Third party cookies that are used for various validation and security purposes.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Session
                        </td>
                        <td>
                            ASP.NET_SessionID<br />
                            CompareListings<br />
                            TempForm<br />
                            TRACKING<br />
                            UserSettingsSession
                        </td>
                        <td>
                            First party cookies used by sites to identify a user’s session for various features.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Settings
                        </td>
                        <td>
                            UserSettings<br />
                            KeepUserOnSite
                        </td>
                        <td>
                            First party cookie that stores settings and preferences pertaining to features a user has interacted with on our site. The information stored includes screen size, whether to display thumbnails or not, currency to use, units of measurement, previous pages for back links, and approximate location in order to display listings closest to the user at the top of search results.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Tracking
                        </td>
                        <td>
                            OriginalID<br />
                            USERID
                        </td>
                        <td>
                            First party cookies that allows us to collect information about a user’s visit. Including pages visited, IP address, and information about browsers and devices used.
                        </td>
                    </tr>
                </table>

                <p>
                    Some of the above tracking cookie are used to identify repeat visitors or users that have an account with us. If the user has ever logged in, their visit does tie back to their account. We do not share this information with anyone, but we do keep a record to help improve the site, investigate possible issues, etc.<br /><br />

                    Contact information is never stored within the cookies we use and is only collected when a user registers for an account or when certain features are interacted with. Examples include our Send A Message or Contact Us features. In these instances, the user may provide us contact information in order to receive a response either from us or another customer they are contacting through our sites. Please refer to our Privacy Notice to see how we protect the personal data you provide us with.<br /><br />

                    Financial information is never stored within the cookies we use and is only collected in the following instances:<br /><br />

                    <ul>
                        <li>Bidding Rights – When a registered user request the right to bid on auctions hosted on our sites, we ask for a bank name and contact information to verify the user meets our criteria for bidding rights.</li>
                        <li>Partner Sites – Occasionally, we partner with or link out to third party sites that specialize in additional services our customers may be interested in. Examples may include sites that arrange for fuel delivery services, escrow, financing, hauling or other services relevant to the industry. These sites may collect a user’s financial information in order to generate an estimated quote for the service. For convenience, we may pass information about the current page the link is on to the third party site to auto-populate some forms.</li>
                    </ul><br /><br />

                    We also make use of Google Services, such as Google Advertising and Google Analytics. Google Analytics uses its own, completely anonymous, tracking data. Google Analytics does not collect, nor do we provide them any contact or financial information. We want to inform you that you can download and install the Google Analytics opt-out browser add-on, if you do not want your data collected with Google Analytics. Google Analytics Opt-out Browser Add-on<br /><br />


                    <strong>Do we host third-party cookies?</strong><br />
                    Yes and we have itemised these cookies in the table above. Cookies from third-party websites may be stored on your Device with the purpose of personalising and/or improving the content (including advertising content) and/or for security and verification purposes that you may be offered while navigating. Depending on the platforms, some third-party cookies may be stored, including:<br /><br />

                    <ul>
                        <li>Social networking content: we publish content from the following social networking sites on our platforms.: Facebook, Google+, YouTube, Instagram, line@, XING, and Twitter.</li>
                        <li>Therefore, when you click on the content in question on our platforms, a cookie from the relevant social networking site may be stored on your Device.</li>
                        <li>We suggest that you read the cookie policy of these social networks for more information.</li>
                        <li>Flash Cookies are files stored by Flash software. This software is often installed as an extension of your Internet browser in order to display dynamic objects/fields such as animated graphics or videos. Therefore, Flash Cookies may be installed when the Flash application is used.</li>
                        <li>Security and verification: In order to protect our content, network, and users, we utilize the following third party solutions that employ cookies in their services: Google, AddThis, F5 Networks, Cloudflare Inc., and Distil Networks.</li>
                    </ul><br /><br />

                    <strong>Managing and Disabling Cookies</strong><br />
                    You can control the cookies placed and retained on your Device. The Privacy Notice and this Cookie Policy are provided to be transparent about our practices regarding the use of cookies and similar tracking technology and to allow you the opportunity to make an informed choice.<br /><br />

                    Where applicable, we will obtain your prior informed consent to use cookies and other tracking technologies which store or access information on your Device. Most browsers accept cookies automatically but they can be set up to ask for your prior consent before an individual cookie is set on your Device. This will give you control over your cookies, however, it may interfere with your browsing experience as some websites may not function properly and you may not be able to take advantage of all the features offered by our website.<br /><br />

                    For instructions on blocking and deleting cookies, see the privacy and help documentation of your specific browser’s website. If you use more Devices and/or browsers you will need to disable cookies on each Device and on each browser separately. Here are the locations of the cookie settings for all major web browsers:<br /><br />

                    <ul>
                        <li><strong>Internet Explorer</strong> - Tools &gt; Internet Options &gt; Privacy tab.</li>
                        <li><strong>Mozilla Firefox</strong> - Tools &gt; Options &gt; Privacy menu.</li>
                        <li><strong>Safari Users</strong> - Edit &gt; Preferences &gt; Privacy menu.</li>
                        <li><strong>Chrome Users</strong> - Settings &gt; Content Settings &gt; Privacy &gt; Cookies.</li>
                    </ul><br /><br />

                    If you limit the ability of our websites to set cookies, this may prevent you from using certain features of our website properly and your user experience – which is no longer personalized for you – may deteriorate. You may also be able to opt-out from certain cookies through third party cookie management sites. Disabling cookies may prevent you from using certain parts of our websites. If you delete your cookies from the browser, you may need to remember to re-install opt-out cookies.<br /><br />

                    <strong>Where can I get more information about cookies?</strong><br />
                    You can find more information about cookies by visiting the following information websites:<br /><br />

                    <a href="https://www.aboutcookies.org/" target="_blank">www.aboutcookies.org</a><br />
                    <a href="https://www.allaboutcookies.org/" target="_blank">www.allaboutcookies.org</a><br /><br />

                    Last Updated 09 April 2019<br />
                    Copyright © by Sandhills Global, Inc. {new Date().getFullYear()}
                </p>

            </div>
        </div>
    );
}

export default CookiePolicy;